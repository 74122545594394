<script setup>
import Chat from '@/Components/Shared/Chat.vue'
import Header from '@/Components/Shared/Header/Header.vue'
import Socials from '@/Components/Shared/Socials.vue'
import useToastify from '@/Composables/useToastify'
import '@beyonk/gdpr-cookie-consent-banner/dist/style.css'
import dom from '@left4code/tw-starter/dist/js/dom'
import { useClipboard } from '@vueuse/core'
import { ref } from 'vue'
import { onMounted } from 'vue'

onMounted(() => {
  dom('html').removeClass('admin-panel')
  dom('body').removeClass('main').removeClass('error-page').removeClass('login')

  import('@beyonk/gdpr-cookie-consent-banner/dist/esm/bundle.js').then(
    (GdprBanner) => {
      GdprBanner.default(document.body, {
        cookieName: 'upstream_cookie_consent',
        description:
          'This website uses cookies to ensure you get the best experience on our website.',
        settingsLabel: 'Settings',
        acceptLabel: 'Accept',
        rejectLabel: 'Decline',
      })
    }
  )
})

const isPromotion = false
const code = 'BLACKFRIDAY2023'
const copyLabel = ref('Copy')
const { copy } = useClipboard()
const copyCode = () => {
  useToastify({ text: `Copied ${code} to clipboard` })
  copyLabel.value = 'Copied!'
  copy(code)
}
</script>

<template>
  <div class="public">
    <button
      v-if="isPromotion"
      class="text-xs md:text-sm fixed top-0 right-0 left-0 hover:bg-black bg-main backdrop-blur-md transition-color duration-300 text-white z-50 px-2 h-[75px] lg:h-[50px] text-center border-b border-white/10"
      @click="copyCode"
    >
      <p class="md:flex items-center justify-center">
        <Icon name="PiggyBank" class="mr-2 w-4 h-4 lg:block hidden" />
        <strong class="font-bold mr-1"> Black Friday Sale! </strong> 50% off on
        your first month with code
        <span
          class="bg-white/10 mx-1.5 py-0.5 mt-1.5 md:mb-1.5 px-2 rounded-md border border-white/10 text-xs font-bold inline-flex items-center uppercase"
          >BLACKFRIDAY2023
          <span
            class="ml-2 text-[10px] w-14 bg-black/40 px-1 py-0.5 border border-white/30 rounded-[4px] -mr-1.5"
            >{{ copyLabel }}</span
          ></span
        >
        at checkout.
      </p>
    </button>

    <div
      class="pb-8 lg:pb-16 bg-beige dark:bg-black dark:text-white"
      :class="{ 'pt-[75px] lg:pt-[50px]': isPromotion }"
    >
      <Header
        :is-header-wide="isHeaderWide"
        :mobile-menu-class="isPromotion ? '!top-[75px] lg:!top-[50px]' : ''"
      />
      <slot />
    </div>
    <footer
      class="py-10 lg:py-16 border-t border-t-black border-opacity-10 bg-white dark:border-t-white dark:border-opacity-5 dark:bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] dark:from-grape-800 dark:via-grape-900 dark:to-black"
    >
      <div class="container px-4 lg:px-0 lg:text-left relative">
        <h2
          class="text-xl md:text-4xl xl:text-6xl text-main dark:text-white font-unbounded"
        >
          Let's take your streaming<br />
          game to the next level
        </h2>
        <nav
          class="border-t border-main dark:border-white border-b dark:border-opacity-20 mt-8 mb-8 pt-6 pb-6 flex lg:flex-row flex-col"
        >
          <ul class="flex lg:justify-between">
            <li
              v-for="item in [
                { label: 'FAQ', url: '/faq' },
                { label: 'Pricing', url: '/#pricing' },
                {
                  label: 'Contact',
                  url: 'mailto:contact@upstream.so',
                  aTag: true,
                },
              ]"
              :key="item.label"
            >
              <a
                v-if="item.aTag"
                :href="item.url"
                class="text-main dark:text-white block py-4 lg:pr-4 px-1 font-unbounded uppercase lg:text-xl hover:text-black dark:hover:text-grape"
                >{{ item.label }}</a
              >
              <Link
                v-else
                :href="item.url"
                class="text-main dark:text-white block py-4 lg:pr-4 px-1 font-unbounded uppercase lg:text-xl hover:text-black dark:hover:text-grape"
                >{{ item.label }}</Link
              >
            </li>
          </ul>

          <ul class="lg:ml-auto flex lg:justify-between items-center">
            <li
              v-for="item in [
                { label: 'Privacy policy', url: '/privacy-policy' },
                // { label: 'Cookies', url: '/cookies' },
                { label: 'Terms & Conditions', url: '/terms-of-service' },
              ]"
              :key="item.label"
            >
              <Link
                :href="item.url"
                class="text-main dark:text-white block py-4 px-1 lg:pl-4 font-unbounded uppercase text-xs lg:text-xl hover:text-black dark:hover:text-grape"
                >{{ item.label }}</Link
              >
            </li>
          </ul>
        </nav>
        <Socials />
      </div>
      <Chat />
    </footer>
  </div>
</template>
