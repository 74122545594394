<template>
  <ul class="lg:absolute right-0 top-0 flex items-center">
    <li>
      <a
        href="https://www.instagram.com/upstream.so"
        class="text-main dark:text-white p-2 block hover:text-black dark:hover:text-citrus"
        target="_blank"
        ><svg
          class="w-8 h-8"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81v8.37C2 19.83 4.17 22 7.81 22h8.37c3.64 0 5.81-2.17 5.81-5.81V7.81C22 4.17 19.83 2 16.19 2ZM12 15.88c-2.14 0-3.88-1.74-3.88-3.88 0-2.14 1.74-3.88 3.88-3.88 2.14 0 3.88 1.74 3.88 3.88 0 2.14-1.74 3.88-3.88 3.88Zm5.92-9c-.05.12-.12.23-.21.33-.1.09-.21.16-.33.21a.995.995 0 0 1-1.09-.21c-.09-.1-.16-.21-.21-.33A.995.995 0 0 1 16 6.5c0-.13.03-.26.08-.38.05-.13.12-.23.21-.33.23-.23.58-.34.9-.27.07.01.13.03.19.06.06.02.12.05.18.09.05.03.1.08.15.12.09.1.16.2.21.33.05.12.08.25.08.38s-.03.26-.08.38Z"
            fill="currentColor"
          /></svg
      ></a>
    </li>
    <li>
      <a
        href="https://twitter.com/upstreamso"
        class="text-main dark:text-white p-2 block hover:text-black dark:hover:text-citrus"
        target="_blank"
        ><svg
          class="w-8 h-8"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100"
          height="100"
          viewBox="0 0 24 24"
        >
          <path
            d="M 2.3671875 3 L 9.4628906 13.140625 L 2.7402344 21 L 5.3808594 21 L 10.644531 14.830078 L 14.960938 21 L 21.871094 21 L 14.449219 10.375 L 20.740234 3 L 18.140625 3 L 13.271484 8.6875 L 9.2988281 3 L 2.3671875 3 z M 6.2070312 5 L 8.2558594 5 L 18.033203 19 L 16.001953 19 L 6.2070312 5 z"
            fill="currentColor"
          ></path></svg
      ></a>
    </li>
    <li>
      <a
        href="https://www.youtube.com/@upstreamso"
        class="text-main dark:text-white p-2 block hover:text-black dark:hover:text-citrus"
        target="_blank"
        ><svg
          class="w-8 h-8"
          width="800"
          height="800"
          viewBox="0 -3 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M251.988 7291.586v-5.612c1.993.938 3.536 1.843 5.36 2.82-1.505.834-3.367 1.77-5.36 2.792m11.103-8.403c-.344-.453-.93-.805-1.553-.922-1.833-.348-13.267-.349-15.099 0-.5.094-.945.32-1.328.673-1.611 1.495-1.106 9.518-.718 10.817.164.562.375.968.64 1.235.343.352.812.594 1.351.703 1.51.312 9.284.486 15.122.047a2.62 2.62 0 0 0 1.39-.712c1.49-1.49 1.388-9.962.195-11.841"
            transform="translate(-300 -7442) translate(56 160)"
            fill="currentColor"
          /></svg
      ></a>
    </li>
    <li>
      <a
        href="https://www.facebook.com/upstream.so"
        class="text-main dark:text-white p-2 block hover:text-black dark:hover:text-citrus"
        target="_blank"
        ><svg
          class="w-7 h-7"
          viewBox="0 0 1920 1920"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1168.737 487.897c44.672-41.401 113.824-36.889 118.9-36.663l289.354-.113 6.317-417.504L1539.65 22.9C1511.675 16.02 1426.053 0 1237.324 0 901.268 0 675.425 235.206 675.425 585.137v93.97H337v451.234h338.425V1920h451.234v-789.66h356.7l62.045-451.233H1126.66v-69.152c0-54.937 14.214-96.112 42.078-122.058"
            fill="currentColor"
          /></svg
      ></a>
    </li>
  </ul>
</template>
