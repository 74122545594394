<script setup>
import { onMounted, ref, computed } from 'vue'
import useRoute from '@/Composables/useRoute'
import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-vue3'
import ThemeMode from '@/Components/Shared/Header/ThemeMode.vue'
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'
import Logo from '@/Components/Shared/Header/Logo.vue'
const user = computed(() => usePage().props.value?.auth.user)

const route = useRoute
const sticky = ref(false)
const isNavigationVisible = ref(false)

defineProps({
  mobileMenuClass: {
    type: String,
    default: '',
  },
})

Inertia.on('start', () => {
  isNavigationVisible.value = false
})

onMounted(() => {
  window.addEventListener('scroll', () => {
    sticky.value = window.scrollY > 100
  })
})

const menuItems = computed(() => {
  const items = [
    {
      name: 'Features',
      href: '/#feature-1',
    },
    {
      name: 'Pricing',
      href: '/#pricing',
    },
    {
      name: 'Reviews',
      href: '/#reviews',
    },
    {
      name: 'FAQ',
      href: '/faq',
    },
    {
      name: 'Blog',
      href: '/blog/',
      tag: 'a',
    },
  ]

  if (!user.value) {
    items.push({
      name: 'Log in',
      href: '/login/',
      'data-cy': 'login-link',
    })
  }

  return items
})
</script>

<template>
  <div
    class="fixed px-4 py-3 lg:py-0 lg:px-0 items-center flex z-40 top-0 lg:top-8 left-0 right-0 lg:bg-transparent transition-colors"
    :class="{ 'bg-transparent': sticky }"
  >
    <Link
      class="lg:hidden transition-all left-0 right-0 absolute"
      :class="[{ 'opacity-0 invisible': sticky }]"
      href="/"
      ><Logo class="justify-center"
    /></Link>
    <CtaButton
      border-style="faint"
      class="ml-auto top-2 right-2 lg:hidden z-50 !p-3 !bg-black !text-white dark:!bg-black dark:!text-white duration-300 !border-white/20 !rounded-xl transition-transform"
      variant="rounded"
      size="small"
      :class="[
        {
          'rotate-180 !bg-main dark:!bg-main !text-white': isNavigationVisible,
        },
      ]"
      @click="isNavigationVisible = !isNavigationVisible"
      ><Icon name="Menu" />
    </CtaButton>
    <nav
      class="px-4 lg:px-4y lg:mx-auto lg:rounded-full backdrop-blur-xl inline-flex flex-col lg:flex-row lg:items-center lg:border lg:border-transparent transition-all duration-300 fixed lg:relative lg:!translate-x-0 inset-0 bg-black lg:bg-transparent"
      :class="[
        {
          'lg:text-dark lg:bg-white dark:lg:bg-black lg:!bg-opacity-50 lg:!border-black dark:lg:!border-neutral-800 lg:!border-opacity-10':
            sticky,
        },
        { 'translate-x-full': !isNavigationVisible },
        mobileMenuClass,
      ]"
    >
      <ThemeMode />

      <Link href="/"
        ><Logo
          class="pt-6 mb-5 lg:mb-0 lg:pt-0 lg:mr-6 lg:ml-2 justify-center text-white lg:dark:text-white lg:text-main"
      /></Link>
      <ul
        class="flex items-center text-xl lg:text-base lg:mx-auto font-body flex-col lg:flex-row relative"
      >
        <li
          v-for="(item, index) in menuItems"
          :key="index"
          :class="{ 'lg:ml-6': index !== 0 }"
        >
          <component
            :is="item.tag || 'Link'"
            :href="item.href"
            class="px-3 py-6 block text-white lg:text-black dark:text-white hover:opacity-50"
            :data-cy="item['data-cy']"
            >{{ item.name }}</component
          >
        </li>
      </ul>

      <CtaButton
        v-if="user"
        :href="route('dashboard')"
        size="small"
        type="link"
        class="lg:ml-6 dark:bg-white dark:text-black dark:hover:!bg-grape"
        :class="{ '!bg-white !text-black': isNavigationVisible }"
        >Dashboard</CtaButton
      >

      <CtaButton
        v-else
        :href="route('register')"
        size="small"
        type="link"
        class="ml-6 dark:bg-white dark:text-black dark:hover:!bg-grape"
        :class="{ '!bg-white !text-black': isNavigationVisible }"
        data-cy="register-button"
        >Get started</CtaButton
      >

      <slot name="theme" />
    </nav>
  </div>
</template>
